import GetUserRoles from '@/mixins/getUserRoles'

export default {
  mixins: [GetUserRoles],

  methods: {
    hasAnyRequiredRole(requiredRoles) {
      const roles = Array.isArray(requiredRoles) ? requiredRoles : [requiredRoles]
      return roles.some((role) => this.userRoles.includes(role))
    },
  },
}
